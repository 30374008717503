import React from "react";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled, { css } from "styled-components";

import { PageLayout } from "src/components/layout/PageLayout";
import { Carousel } from "src/components/Carousel";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata } from "src/utils/image";
import { Text } from "src/components/Text";

import CloseIcon from "assets/images/close.svg";

import "pure-react-carousel/dist/react-carousel.es.css";

interface GalleryPageProps extends PageProps {
  data: GatsbyTypes.GalleryPageQuery;
}

const GalleryPage: React.FC<GalleryPageProps> = ({ data }) => {
  const GalleryImages = React.useMemo(() => (data.strapiGallery?.images || []).map(
    (imageData, i) => {
      const [ image, alt ] = getImageMetadata(imageData);

      return (
        <OnClickWrapper key={imageData?.id || i} onClick={() => setFullScreen(true)}>
          <GatsbyImage image={image as IGatsbyImageData} alt={alt} />
        </OnClickWrapper>
      );
    }
  ), [ data.strapiGallery?.images ]);

  const [ fullScreen, setFullScreen ] = React.useState(false);

  return (
    <PageLayout title="Gallery" width="extra-wide">
      <CarouselWrapper fullScreen={fullScreen}>
        {fullScreen && (
          <CloseIconButton onClick={() => setFullScreen(false)}>
            <CloseIcon height="20px" width="20px" />
          </CloseIconButton>
        )}
        <Carousel slides={GalleryImages} fullScreen={fullScreen} />
        {!fullScreen && data.strapiGallery?.caption && (
          <CaptionText as="h3">
            {data.strapiGallery.caption}
          </CaptionText>
        )}
      </CarouselWrapper>
    </PageLayout>
  );
};

export default GalleryPage;

export const query = graphql`
  query GalleryPage {
    strapiGallery {
      images {
        id
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      caption
    }
  }
`;

const OnClickWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  .gatsby-image-wrapper {
    width: auto!important;

    ${breakpoint("tabletSP")} {
      align-self: center;
      width: 100%!important;
      max-height: 100vh;
    }
  }
`;

const CarouselWrapper = styled.div<{ fullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;

  img {
    object-fit: contain!important;
  }

  ${({ theme, fullScreen }) => css`
    ${fullScreen ? css`
      margin-top: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      background-color: ${theme.colors.background};
    ` : css`
      ${breakpoint("tabletLL")} {
        margin-top: 4rem;
      }
      ${OnClickWrapper} {
        &:hover {
          cursor: pointer;
        }
      }
    `}
  `}
`;

const CloseIconButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};

  &:hover {
    cursor: pointer;
  }
  
  &:active {
    background-color: ${({ theme }) => theme.colors.background}88;
  }
`;

const CaptionText = styled(Text)`
  align-self: center;
  margin: 0.5rem 1rem;
  text-align: center;
`;