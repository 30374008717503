import { IGatsbyImageData } from "gatsby-plugin-image";

export interface IGatsbyImage {
  id?: string;
  alternativeText?: string;
  localFile?: {
    childImageSharp?: {
      gatsbyImageData?: IGatsbyImageData;
    };
  };
}

type ImageMetadata = [ IGatsbyImageData | undefined, string ];

export const getImageMetadata = (image?: IGatsbyImage): ImageMetadata => {
  const imageData = image?.localFile?.childImageSharp?.gatsbyImageData;
  const alt = image?.alternativeText || "";

  return [ imageData, alt ];
};